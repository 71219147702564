import { NAVIGATION_MENUS_SLUGS } from "../../constants";
import { EditIcon, ImageIcon } from "../../materialUi/icons";

export const bannerTypeValueConstants = {
  CAROUSEL: "Carousel",
  SINGLE: "Single",
  PRODUCTCAROUSEL: "ProductCarousel",
  SLIDER: "Slider",
  CUSTOM: "Custom",
  HTML: "HTML",
  CURRENTFSPRODUCTCAROUSEL: "CurrentFSProductCarousel",
  CURRENTFSV2PRODUCTCAROUSEL: "CurrentFSV2ProductCarousel",
  TAB: "tab",
  COLUMN: "column",
  LIST: "list"
};

export const listBannerTypeValueConstants = {
  ACCORDION: "Accordion",
  BULLET: "Bullet"
};

export const bannerTypeOptions = [
  { value: bannerTypeValueConstants.CAROUSEL, label: "Carousel" },
  { value: bannerTypeValueConstants.SINGLE, label: "Single" },
  {
    value: bannerTypeValueConstants.PRODUCTCAROUSEL,
    label: "Product-Carousel"
  },
  { value: bannerTypeValueConstants.SLIDER, label: "Slider" },
  { value: bannerTypeValueConstants.CUSTOM, label: "Custom" },
  { value: bannerTypeValueConstants.HTML, label: "HTML" },
  {
    value: bannerTypeValueConstants.CURRENTFSPRODUCTCAROUSEL,
    label: "Current FS Product-Carousel"
  },
  {
    value: bannerTypeValueConstants.CURRENTFSV2PRODUCTCAROUSEL,
    label: "Current FS V2 Product-Carousel"
  },
  { value: bannerTypeValueConstants.TAB, label: "Tab" },
  { value: bannerTypeValueConstants.COLUMN, label: "Column" },
  { value: bannerTypeValueConstants.LIST, label: "List" }
];

export const sectionsPageBannerTypeOptions = [
  { value: bannerTypeValueConstants.CAROUSEL, label: "Carousel" },
  { value: bannerTypeValueConstants.SINGLE, label: "Single" },
  { value: bannerTypeValueConstants.HTML, label: "HTML" },
  { value: bannerTypeValueConstants.SLIDER, label: "Slider" },
  { value: bannerTypeValueConstants.CUSTOM, label: "Custom" },
  { value: bannerTypeValueConstants.COLUMN, label: "Column" },
  { value: bannerTypeValueConstants.LIST, label: "List" }
];

export const urlPageBannerTypeOptions = [
  { value: bannerTypeValueConstants.SINGLE, label: "Single" },
  { value: bannerTypeValueConstants.HTML, label: "HTML" },
  { value: bannerTypeValueConstants.SLIDER, label: "Slider" }
];

export const listBannerTypeOptions = [
  { value: listBannerTypeValueConstants.ACCORDION, label: "Accordion" },
  { value: listBannerTypeValueConstants.BULLET, label: "Bullet" }
];

export const bulletTypeOptions = [
  { value: "style1", label: "Style 1" },
  { value: "style2", label: "Style 2" },
  { value: "style3", label: "Style 3" },
  { value: "style4", label: "Style 4" },
  { value: "style5", label: "Style 5" },
  { value: "style6", label: "Style 6" }
];

export const bannerStyleOptions = [
  { value: "Class1", label: "Class1" },
  { value: "Class2", label: "Class3" },
  { value: "Class3", label: "Class2" }
];

export const bannerBackgroundOptions = [
  { value: "backgroundColor", label: "Color" }
];

export const bannerDesktopBackgroundOptions = [
  ...bannerBackgroundOptions,
  { value: "backgroundImage", label: "Background Image" },
  { value: "backgroundGradient", label: "Gradient" }
];

export const bannerGradientTypes = [
  { value: "linear-gradient", label: "Linear" }
  // { value: 'radial-gradient', label: 'Radial' },
];

export const rowWidthValues = {
  FULL_WIDTH: "full-width",
  CONTAINER_WIDTH: "container-width",
  CONTAINER_WIDTH_FULL_BG: "container-width-full-bg",
  CUSTOM_WIDTH: "custom-width"
};

export const rowWidthOptions = [
  { value: rowWidthValues.FULL_WIDTH, label: "Full width" },
  { value: rowWidthValues.CONTAINER_WIDTH, label: "Container width" }
];

export const rowWidthOptionsDesktop = [
  ...rowWidthOptions,
  { value: rowWidthValues.CUSTOM_WIDTH, label: "Custom width" }
];
export const productCarousalRowWidthOptions = [
  { value: rowWidthValues.CONTAINER_WIDTH, label: "Container width" }
];

export const indicatorOptions = [
  { value: "rectangle", label: "Rectangle" },
  { value: "bullet", label: "Bullet" },
  { value: "none", label: "None" }
];

export const indicatorOptionsMobile = [
  { value: "none", label: "None" },
  { value: "scroll", label: "Scroll" }
];

export const controllerIndicatorOptions = [
  { value: "default", label: "Default" },
  { value: "none", label: "None" }
];

export const autoScrollOptions = [
  { value: "scroll", label: "Scroll" },
  { value: "none", label: "None" }
];

export const autoScrollSpeedOptions = [
  { value: 3, label: "3" },
  { value: 4, label: "4" },
  { value: 5, label: "5" },
  { value: 6, label: "6" },
  { value: 7, label: "7" },
  { value: 8, label: "8" },
  { value: 9, label: "9" },
  { value: 10, label: "10" }
];

export const customModuleNameConstants = {
  STORYLY: "storyly",
  QUICKFILTER: "quickFilter",
  LISA: "lisa",
  LISAPLAYER: "lisaPlayer",
  LISAUPCOMING: "lisaUpcoming",
  LISARECORDED: "lisaRecorded",
  LIVEFLOATINGBUTTON: "liveFloatingButton",
  BRAMEGAMIFICATION: "brameGamification",
  NEWSLETTER: "newsLetter",
  FLASHSALE: "flashSale",
  BEACONS: "beacons",
  GIFTCARD: "giftCard",
  DOWNLOADMOBILEAPP: "downloadMobileApp",
  PAYMENTICONS: "paymentIcons",
  HELPLINE: "helpline",
  CUSTOMERCARE: "customerCare",
  WHATSAPP: "whatsapp",
  CONNECT_WITH_US: "connectWithUs",
  COUNTRY_SELECTION: "countrySelection",
  VIDEO_PLAYER: "videoPlayer"
};

export const customModuleNames = [
  {
    value: customModuleNameConstants.STORYLY,
    label: "Storyly",
    info: {
      desktop: [
        {
          heading: "WEB",
          key: "token",
          value:
            "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJhY2NfaWQiOjU2OTYsImFwcF9pZCI6MTA3NTQsImluc19pZCI6MTE0Mzh9.2keEvNYkTsGOBHNPZq_EW4hGIFfSFK2kqz3Y97Sbi3w"
        }
      ],
      mobile: [
        {
          heading: "Android",
          key: "token_android",
          value:
            "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJhY2NfaWQiOjU2OTYsImFwcF9pZCI6MTA2MDIsImluc19pZCI6MTMxMzh9.d4FK-OvUPx7ePfpfydE_2NLDphXGu1teTaXJWm_u0vc"
        },
        {
          heading: "iOS",
          key: "token_ios",
          value:
            "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJhY2NfaWQiOjU2OTYsImFwcF9pZCI6MTA2MDEsImluc19pZCI6MTMxMzl9.Yan2A0jeXZ8hSCoJbduu5KoZkqBBzWKmuLZHvayQSYw"
        }
      ],
      tablet: [
        {
          heading: "Tablet WEB",
          key: "token",
          value:
            "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJhY2NfaWQiOjU2OTYsImFwcF9pZCI6MTA3NTQsImluc19pZCI6MTE0Mzh9.2keEvNYkTsGOBHNPZq_EW4hGIFfSFK2kqz3Y97Sbi3w"
        },
        {
          heading: "Tablet Android",
          key: "token_android",
          value:
            "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJhY2NfaWQiOjU2OTYsImFwcF9pZCI6MTA2MDIsImluc19pZCI6MTMxMzh9.d4FK-OvUPx7ePfpfydE_2NLDphXGu1teTaXJWm_u0vc"
        },
        {
          heading: "Tablet iOS",
          key: "token_ios",
          value:
            "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJhY2NfaWQiOjU2OTYsImFwcF9pZCI6MTA2MDEsImluc19pZCI6MTMxMzl9.Yan2A0jeXZ8hSCoJbduu5KoZkqBBzWKmuLZHvayQSYw"
        }
      ]
    }
  },
  {
    value: customModuleNameConstants.QUICKFILTER,
    label: "QuickFilter",
    info: {
      desktop: [
        {
          heading: "WEB",
          key: "image",
          value: "image URL"
        }
      ],
      mobile: [
        {
          heading: "Mobile",
          key: "image",
          value: "image URL"
        }
      ],
      tablet: [
        {
          heading: "Tablet",
          key: "image",
          value: "image URL"
        }
      ]
    }
  },
  {
    value: customModuleNameConstants.LISA,
    label: "Lisa",
    info: {
      desktop: [
        {
          heading: "WEB",
          key: "module name",
          value: "LiveFloatingButton"
        },
        {
          heading: "WEB",
          key: "type",
          value: "LISA/BFL"
        },
        {
          heading: "WEB",
          key: "leadTime",
          value: "64800"
        },
        {
          heading: "WEB",
          key: "url",
          value: "/lisa-demo/"
        },
        {
          heading: "WEB",
          key: "position",
          value: "tr, br, tl, bl"
        }
      ],
      mobile: [
        {
          heading: "Mobile",
          key: "module name",
          value: "Lisa"
        }
      ],
      tablet: [
        {
          heading: "Tablet",
          key: "module name",
          value: "Lisa"
        }
      ]
    }
  },
  {
    value: customModuleNameConstants.LISAPLAYER,
    label: "LisaPlayer",
    info: []
  },
  {
    value: customModuleNameConstants.LISAUPCOMING,
    label: "LisaUpcoming",
    info: []
  },
  {
    value: customModuleNameConstants.LISARECORDED,
    label: "LisaRecorded",
    info: []
  },
  {
    value: customModuleNameConstants.LIVEFLOATINGBUTTON,
    label: "LiveFloatingButton(Responsive app Only)",
    info: []
  },
  {
    value: customModuleNameConstants.BRAMEGAMIFICATION,
    label: "BrameGamification",
    info: {
      desktop: [
        {
          heading: "WEB",
          key: "iframeURL",
          value: "Game URL"
        }
      ],
      mobile: [
        {
          heading: "Mobile",
          key: "iframeURL",
          value: "Game URL"
        }
      ],
      tablet: [
        {
          heading: "Tablet",
          key: "iframeURL",
          value: "Game URL"
        }
      ]
    }
  },
  {
    value: customModuleNameConstants.NEWSLETTER,
    label: "News Letter",
    info: []
  },
  { value: customModuleNameConstants.FLASHSALE, label: "Flash Sale", info: [] },
  {
    value: customModuleNameConstants.BEACONS,
    label: "Beacons(Mobile app Only)",
    info: []
  },
  { value: customModuleNameConstants.GIFTCARD, label: "Gift Card", info: [] },
  {
    value: customModuleNameConstants.DOWNLOADMOBILEAPP,
    label: "Download Mobile App(Responsive app Only)",
    info: []
  },
  {
    value: customModuleNameConstants.HELPLINE,
    label: "Footer-Helpline",
    info: []
  },
  {
    value: customModuleNameConstants.CUSTOMERCARE,
    label: "Footer-Customer Care",
    info: []
  },
  {
    value: customModuleNameConstants.WHATSAPP,
    label: "Footer-Whatsapp",
    info: []
  },
  {
    value: customModuleNameConstants.CONNECT_WITH_US,
    label: "Footer-Connect With Us",
    info: []
  },
  {
    value: customModuleNameConstants.COUNTRY_SELECTION,
    label: "Footer-Country Selection",
    info: []
  },
  {
    value: customModuleNameConstants.VIDEO_PLAYER,
    label: "Video-Player",
    info: []
  }
];

export const customModulesWithOneStaticRow = [
  customModuleNameConstants.FLASHSALE,
  customModuleNameConstants.BEACONS,
  customModuleNameConstants.GIFTCARD,
  customModuleNameConstants.DOWNLOADMOBILEAPP,
  customModuleNameConstants.CONNECT_WITH_US
];

export const customModulesWithTwoStaticRow = [
  customModuleNameConstants.CUSTOMERCARE,
  customModuleNameConstants.VIDEO_PLAYER
];

export const customModulesWithThreeStaticRow = [];

export const customModulesWithFourStaticRow = [
  customModuleNameConstants.HELPLINE,
  customModuleNameConstants.WHATSAPP
];

export const customModuleRows = {
  [customModuleNameConstants.STORYLY]: [
    { key: "moduleName", value: customModuleNameConstants.STORYLY }
  ],
  [customModuleNameConstants.QUICKFILTER]: [
    { key: "moduleName", value: customModuleNameConstants.QUICKFILTER }
  ],
  [customModuleNameConstants.LISA]: [
    { key: "moduleName", value: customModuleNameConstants.LISA }
  ],
  [customModuleNameConstants.LISAPLAYER]: [
    { key: "moduleName", value: customModuleNameConstants.LISAPLAYER }
  ],
  [customModuleNameConstants.LISAUPCOMING]: [
    { key: "moduleName", value: customModuleNameConstants.LISAUPCOMING }
  ],
  [customModuleNameConstants.LISARECORDED]: [
    { key: "moduleName", value: customModuleNameConstants.LISARECORDED }
  ],
  [customModuleNameConstants.LIVEFLOATINGBUTTON]: [
    { key: "moduleName", value: customModuleNameConstants.LIVEFLOATINGBUTTON }
  ],
  [customModuleNameConstants.BRAMEGAMIFICATION]: [
    { key: "moduleName", value: customModuleNameConstants.BRAMEGAMIFICATION }
  ],
  [customModuleNameConstants.NEWSLETTER]: [
    { key: "moduleName", value: customModuleNameConstants.NEWSLETTER }
  ],
  [customModuleNameConstants.FLASHSALE]: [
    { key: "moduleName", value: customModuleNameConstants.FLASHSALE },
    { key: "pageContent", value: "" }
  ],
  [customModuleNameConstants.BEACONS]: [
    { key: "moduleName", value: customModuleNameConstants.BEACONS },
    { key: "bannerUrl", value: "" }
  ],
  [customModuleNameConstants.GIFTCARD]: [
    { key: "moduleName", value: customModuleNameConstants.GIFTCARD },
    { key: "price", value: "" }
  ],
  [customModuleNameConstants.DOWNLOADMOBILEAPP]: [
    { key: "moduleName", value: customModuleNameConstants.DOWNLOADMOBILEAPP },
    { key: "qrCodeUrl", value: "" }
  ],
  [customModuleNameConstants.CUSTOMERCARE]: [
    { key: "moduleName", value: customModuleNameConstants.CUSTOMERCARE },
    { key: "iconUrl", value: "" },
    { key: "email", value: "" }
  ],
  [customModuleNameConstants.HELPLINE]: [
    { key: "moduleName", value: customModuleNameConstants.HELPLINE },
    { key: "iconUrl", value: "" },
    { key: "contact", value: "" },
    { key: "en_infoText", value: "" },
    { key: "ar_infoText", value: "" }
  ],
  [customModuleNameConstants.WHATSAPP]: [
    { key: "moduleName", value: customModuleNameConstants.WHATSAPP },
    { key: "iconUrl", value: "" },
    { key: "contact", value: "" },
    { key: "en_infoText", value: "" },
    { key: "ar_infoText", value: "" }
  ],
  [customModuleNameConstants.CONNECT_WITH_US]: [
    { key: "moduleName", value: customModuleNameConstants.CONNECT_WITH_US },
    { key: "json", value: "" }
  ],
  [customModuleNameConstants.COUNTRY_SELECTION]: [
    { key: "moduleName", value: customModuleNameConstants.COUNTRY_SELECTION }
  ],
  [customModuleNameConstants.VIDEO_PLAYER]: [
    { key: "moduleName", value: customModuleNameConstants.VIDEO_PLAYER },
    { key: "videoId", value: "" },
    { key: "videoUrl", value: "" }
  ]
};

export const backgroundSizeOptions = [
  { value: "auto", label: "Auto" },
  { value: "length", label: "Length" },
  { value: "cover", label: "Cover" },
  { value: "contain", label: "Contain" }
];

export const backgroundRepeatOptions = [
  { value: "repeat", label: "Repeat" },
  { value: "repeat-x", label: "Repeat-x" },
  { value: "repeat-y", label: "Repeat-y" },
  { value: "no-repeat", label: "No-repeat" }
];

export const backgroundPositionOptions = [
  { value: "top", label: "Top" },
  { value: "left", label: "Left" },
  { value: "bottom", label: "Bottom" },
  { value: "right", label: "Right" },
  { value: "center", label: "Center" }
];

export const customInputTypes = {
  TEXT: "text",
  TEXT_AREA: "textarea",
  RADIO: "radio",
  FILTER: "filter",
  MEDIA: "media",
  JSON: "json"
};

export const customConfigureType = [
  { value: customInputTypes.TEXT, label: "Text" },
  { value: customInputTypes.TEXT_AREA, label: "Textarea" },
  { value: customInputTypes.RADIO, label: "Radio" },
  { value: customInputTypes.MEDIA, label: "Media" },
  { value: customInputTypes.JSON, label: "JSON" },
  { value: customInputTypes.FILTER, label: "Filter", isDisabled: true }
];

export const customConfigureTypeForFilterPage = [
  { value: customInputTypes.TEXT, label: "Text", isDisabled: true },
  { value: customInputTypes.TEXT_AREA, label: "Textarea", isDisabled: true },
  { value: customInputTypes.RADIO, label: "Radio", isDisabled: true },
  { value: customInputTypes.MEDIA, label: "Media", isDisabled: true },
  { value: customInputTypes.JSON, label: "JSON", isDisabled: true },
  { value: customInputTypes.FILTER, label: "Filter" }
];

export const filterOptions = [
  { value: "select", label: "Single Select" },
  { value: "selectsearch", label: "Single Select + Search" },
  { value: "multiselect", label: "Multi Select" },
  { value: "multiselectsearch", label: "Multi Select + Search" },
  { value: "size", label: "Size" },
  { value: "price", label: "Price" }
];

export const attributeKeyValueMapping = {
  color: "Color",
  showImageIcon: "Show Image Icon",
  searchPlaceholder: "Search Placeholder",
  showSelectCount: "Show Select Count",
  showClearAllButton: "Show Clear All Button",
  clearAllButtonText: "Clear All Button Text",
  clearAllButtonColor: "Clear All Button Color",
  buttonText: "Button Text",
  buttonColor: "Button Color",
  buttonFontColor: "Button Font Color",
  headingText: "Heading Text",
  subHeadingText: "SubHeading Text",
  placeholder: "Placeholder"
};

export const filterAttributeOptions = {
  select: {
    options: [
      { value: "color", label: attributeKeyValueMapping["color"] },
      {
        value: "showImageIcon",
        label: attributeKeyValueMapping["showImageIcon"]
      }
    ]
  },
  selectsearch: {
    options: [
      { value: "color", label: attributeKeyValueMapping["color"] },
      {
        value: "showImageIcon",
        label: attributeKeyValueMapping["showImageIcon"]
      },
      {
        value: "searchPlaceholder",
        label: attributeKeyValueMapping["searchPlaceholder"]
      }
    ]
  },
  multiselect: {
    options: [
      { value: "color", label: attributeKeyValueMapping["color"] },
      {
        value: "showImageIcon",
        label: attributeKeyValueMapping["showImageIcon"]
      },
      {
        value: "showSelectCount",
        label: attributeKeyValueMapping["showSelectCount"]
      },
      {
        value: "showClearAllButton",
        label: attributeKeyValueMapping["showClearAllButton"]
      },
      {
        value: "clearAllButtonText",
        label: attributeKeyValueMapping["clearAllButtonText"]
      },
      {
        value: "clearAllButtonColor",
        label: attributeKeyValueMapping["clearAllButtonColor"]
      }
    ]
  },
  multiselectsearch: {
    options: [
      { value: "color", label: attributeKeyValueMapping["color"] },
      {
        value: "showImageIcon",
        label: attributeKeyValueMapping["showImageIcon"]
      },
      {
        value: "showSelectCount",
        label: attributeKeyValueMapping["showSelectCount"]
      },
      {
        value: "showClearAllButton",
        label: attributeKeyValueMapping["showClearAllButton"]
      },
      {
        value: "clearAllButtonText",
        label: attributeKeyValueMapping["clearAllButtonText"]
      },
      {
        value: "clearAllButtonColor",
        label: attributeKeyValueMapping["clearAllButtonColor"]
      },
      {
        value: "searchPlaceholder",
        label: attributeKeyValueMapping["searchPlaceholder"]
      }
    ]
  },
  size: {
    options: [
      { value: "buttonText", label: attributeKeyValueMapping["buttonText"] },
      { value: "buttonColor", label: attributeKeyValueMapping["buttonColor"] },
      {
        value: "buttonFontColor",
        label: attributeKeyValueMapping["buttonFontColor"]
      },
      { value: "headingText", label: attributeKeyValueMapping["headingText"] },
      {
        value: "subHeadingText",
        label: attributeKeyValueMapping["subHeadingText"]
      }
    ]
  },
  price: {
    options: [
      { value: "placeholder", label: attributeKeyValueMapping["placeholder"] },
      { value: "color", label: attributeKeyValueMapping["color"] }
    ]
  }
};

export const defaultAttributeValues = {
  color: "#ffffff",
  showImageIcon: false,
  searchPlaceholder: "Search",
  showSelectCount: false,
  showClearAllButton: false,
  clearAllButtonText: "Clear",
  clearAllButtonColor: "#ffffff",
  buttonText: "",
  buttonColor: "#ffffff",
  buttonFontColor: "#ffffff",
  headingText: "",
  subHeadingText: "",
  placeholder: ""
};

export const textInputFilterAttributes = [
  "searchPlaceholder",
  "clearAllButtonText",
  "buttonText",
  "headingText",
  "subHeadingText",
  "placeholder"
];

export const checkboxFilterAttributes = [
  "showImageIcon",
  "showSelectCount",
  "showClearAllButton"
];

export const colorFilterAttributes = [
  "color",
  "clearAllButtonColor",
  "buttonColor",
  "buttonFontColor"
];

export const accordionListTypeTabList = [
  { label: "English", value: "english" },
  { label: "Arabic", value: "arabic" }
];

export const TabBannerTypeIconPositionList = [
  { label: "Left", value: "left" },
  { label: "Right", value: "right" }
];

export const TabBannerTypeContentSourceList = [
  { label: "Catalog Pages", value: NAVIGATION_MENUS_SLUGS.PAGES },
  { label: "Sections", value: NAVIGATION_MENUS_SLUGS.SECTIONS }
];

export const tabBannerTypeNavigationType = {
  INLINE: "inline",
  REDIRECTIONURL: "redirectionUrl"
};

export const tabBannerTypeNavigationList = [
  { label: "Inline", value: tabBannerTypeNavigationType.INLINE },
  {
    label: "Redirection Url",
    value: tabBannerTypeNavigationType.REDIRECTIONURL
  }
];

export const tabBannerStyleList = [
  { label: "Style 1", value: "style1" },
  { label: "Style 2", value: "style2" },
  { label: "Style 3", value: "style3" },
  { label: "Style 4", value: "style4" },
  { label: "None", value: "none" }
];

export const tabBannerTypeIconOptions = [
  { value: "edit", label: "Edit", showIcon: true, icon: <EditIcon /> },
  { value: "image", label: "Image", showIcon: true, icon: <ImageIcon /> }
];

export const imageMenuButtonOptions = [
  { value: "edit", label: "Edit", showIcon: true, icon: <EditIcon /> },
  { value: "image", label: "Image", showIcon: true, icon: <ImageIcon /> }
];

export const imageModalAttributes = [
  { label: "ITM Parameter", value: "itm" },
  { label: "Creative Name", value: "creative_name" },
  { label: "Creative Slot", value: "creative_slot" },
  { label: "Promotion Id", value: "promotion_id" },
  { label: "Promotion Name", value: "promotion_name" }
];

export const columnTypeValue = {
  TREE: "tree",
  HTML: bannerTypeValueConstants.HTML,
  SINGLE: bannerTypeValueConstants.SINGLE,
  SLIDER: bannerTypeValueConstants.SLIDER,
  CUSTOM: bannerTypeValueConstants.CUSTOM,
  PRODUCTCAROUSEL: bannerTypeValueConstants.PRODUCTCAROUSEL
};

export const columnTypeList = [
  { label: "Tree", value: columnTypeValue.TREE },
  { label: "HTML", value: columnTypeValue.HTML },
  { label: "Single", value: columnTypeValue.SINGLE },
  { label: "Custom", value: columnTypeValue.CUSTOM },
  { label: "Slider", value: columnTypeValue.SLIDER },
  { label: "Product-Carousel", value: columnTypeValue.PRODUCTCAROUSEL }
];

export const fontStyleValue = {
  BOLD: "bold",
  NORMAL: "normal"
};

export const fontStyleList = [
  { label: "Bold", value: fontStyleValue.BOLD },
  { label: "Normal", value: fontStyleValue.NORMAL }
];

export const textAlignValue = {
  LEFT: "left",
  RIGHT: "right",
  CENTER: "center"
};

export const textAlignList = [
  { label: "Left", value: textAlignValue.LEFT },
  { label: "Right", value: textAlignValue.RIGHT },
  { label: "Center", value: textAlignValue.CENTER }
];

export const menuStyleList = [
  { label: "Style 1", value: "style1" },
  { label: "Style 2", value: "style2" },
  { label: "Style 3", value: "style3" },
  { label: "None", value: "none" }
];

export const ColumnMenuTypeValue = {
  CUSTOM: "custom",
  TEXT: "text"
};

export const ColumnMenuTypeList = [
  { label: "Custom", value: ColumnMenuTypeValue.CUSTOM },
  { label: "Text", value: ColumnMenuTypeValue.TEXT }
];

export const ColumnMenuTypeOptions = {
  CUSTOM: ColumnMenuTypeValue.CUSTOM,
  TEXT: ColumnMenuTypeValue.TEXT
};

export const customColumnModuleNames = [
  { value: customModuleNameConstants.NEWSLETTER, label: "News Letter" },
  { value: customModuleNameConstants.PAYMENTICONS, label: "Payment Icons" }
];

export const bulletTypeStyleList = [
  {
    label: "Style 1",
    URL:
      "https://f.bflcdn.com/f_auto,q_auto/cms1/prod-bflcms-homepage/dynamicBanner/2/236/21696849277807/english/modal/1696849279033_cat-01.jpg?burst=1707973012440"
  },
  {
    label: "Style 2",
    URL:
      "https://f.bflcdn.com/f_auto,q_auto/cms1/prod-bflcms-homepage/dynamicBanner/2/236/21696849373273/english/modal/1696849374615_cat-03.jpg?burst=1707973104256"
  },
  {
    label: "Style 3",
    URL:
      "https://f.bflcdn.com/f_auto,q_auto/cms1/prod-bflcms-homepage/dynamicBanner/2/236/21696849373273/english/modal/1696849374615_cat-03.jpg?burst=1707973104256"
  },
  {
    label: "Style 4",
    URL:
      "https://f.bflcdn.com/f_auto,q_auto/cms1/prod-bflcms-homepage/dynamicBanner/2/236/21696849373273/english/modal/1696849374615_cat-03.jpg?burst=1707973104256"
  },
  {
    label: "Style 5",
    URL:
      "https://f.bflcdn.com/f_auto,q_auto/cms1/prod-bflcms-homepage/dynamicBanner/2/236/21696849373273/english/modal/1696849374615_cat-03.jpg?burst=1707973104256"
  },
  {
    label: "Style 6",
    URL:
      "https://f.bflcdn.com/f_auto,q_auto/cms1/prod-bflcms-homepage/dynamicBanner/2/236/21696849373273/english/modal/1696849374615_cat-03.jpg?burst=1707973104256"
  },
  {
    label: "Style 7",
    URL:
      "https://f.bflcdn.com/f_auto,q_auto/cms1/prod-bflcms-homepage/dynamicBanner/2/236/21696849277807/english/modal/1696849279033_cat-01.jpg?burst=1707973012440"
  },
  {
    label: "Style 8",
    URL:
      "https://f.bflcdn.com/f_auto,q_auto/cms1/prod-bflcms-homepage/dynamicBanner/2/236/21696849373273/english/modal/1696849374615_cat-03.jpg?burst=1707973104256"
  },
  {
    label: "Style 9",
    URL:
      "https://f.bflcdn.com/f_auto,q_auto/cms1/prod-bflcms-homepage/dynamicBanner/2/236/21696849373273/english/modal/1696849374615_cat-03.jpg?burst=1707973104256"
  },
  {
    label: "Style 10",
    URL:
      "https://f.bflcdn.com/f_auto,q_auto/cms1/prod-bflcms-homepage/dynamicBanner/2/236/21696849373273/english/modal/1696849374615_cat-03.jpg?burst=1707973104256"
  },
  {
    label: "Style 11",
    URL:
      "https://f.bflcdn.com/f_auto,q_auto/cms1/prod-bflcms-homepage/dynamicBanner/2/236/21696849373273/english/modal/1696849374615_cat-03.jpg?burst=1707973104256"
  },
  {
    label: "Style 12",
    URL:
      "https://f.bflcdn.com/f_auto,q_auto/cms1/prod-bflcms-homepage/dynamicBanner/2/236/21696849373273/english/modal/1696849374615_cat-03.jpg?burst=1707973104256"
  }
];

export const STORYLY = "storyly";
export const EN_IMAGE_MODAL = "en_imageModal";
export const AR_IMAGE_MODAL = "ar_imageModal";
export const EN_PREVIEW_IMAGE_MODAL = "en_previewImageModal";
export const AR_PREVIEW_IMAGE_MODAL = "ar_previewImageModal";
export const HTML_VIEW_CONTENT_MODAL = "htmlViewContentModal";
export const HTML_VIEW_TITLE_MODAL = "htmlViewTitleModal";
export const BULLET_STYLES_PREVIEW_MODAL = "bulletStylesPreviewModal";
export const INLINE_CSS_MODAL = "inlineCssModal";
export const ADD_EXTERNAL_LINK_MODAL = "AddExternalLinkModal";
export const ADD_MENU_MODAL = "addMenuModal";
export const EDIT_MENU_MODAL = "editMenuModal";
export const ENGLISH = "english";
export const ARABIC = "arabic";
