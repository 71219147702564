export const countdownOptions = [
  { label: "Left", value: "left" },
  { label: "Right", value: "right" },
  { label: "Center", value: "center" }
];

export const countdownTabs = [
  { key: "beforeCountDown", label: "Before Countdown" },
  { key: "afterCountDown", label: "After Countdown" }
];

export const countdownHeadingOptions = [
  { label: "Left", value: "left" },
  { label: "Right", value: "right" },
  { label: "Top", value: "top" },
  { label: "Bottom", value: "bottom" }
];

export const countdownStyleOptions = {
  desktop: {
    options: [
      { label: "Style 0", value: 0, imageUrl: "/images/countdown_style_0.png" },
      { label: "Style 1", value: 1, imageUrl: "/images/countdown_style_1.png" },
      { label: "Style 2", value: 2, imageUrl: "/images/countdown_style_2.png" },
      { label: "Style 3", value: 3, imageUrl: "/images/countdown_style_3.png" },
      { label: "Style 4", value: 4, imageUrl: "/images/countdown_style_4.png" },
      { label: "Style 5", value: 5, imageUrl: "/images/countdown_style_5.png" }
    ]
  },
  mobile: {
    options: [
      { label: "Style 0", value: 0, imageUrl: "/images/countdown_style_0.png" },
      { label: "Style 1", value: 1, imageUrl: "/images/countdown_style_1.png" },
      { label: "Style 2", value: 2, imageUrl: "/images/countdown_style_2.png" },
      { label: "Style 3", value: 3, imageUrl: "/images/countdown_style_3.png" },
      { label: "Style 4", value: 4, imageUrl: "/images/countdown_style_4.png" },
      { label: "Style 5", value: 5, imageUrl: "/images/countdown_style_5.png" }
    ]
  },
  tablet: {
    options: [
      { label: "Style 0", value: 0, imageUrl: "/images/countdown_style_0.png" },
      { label: "Style 1", value: 1, imageUrl: "/images/countdown_style_1.png" },
      { label: "Style 2", value: 2, imageUrl: "/images/countdown_style_2.png" },
      { label: "Style 3", value: 3, imageUrl: "/images/countdown_style_3.png" },
      { label: "Style 4", value: 4, imageUrl: "/images/countdown_style_4.png" },
      { label: "Style 5", value: 5, imageUrl: "/images/countdown_style_5.png" }
    ]
  }
};
