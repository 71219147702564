import React, { useState, useEffect } from "react";
import get from "lodash/get";
import { cloneDeep } from "lodash";
import {
  deviceTypes,
  customModuleNames,
  customModulesWithOneStaticRow,
  customModulesWithTwoStaticRow,
  customModulesWithThreeStaticRow,
  customModulesWithFourStaticRow,
  customModuleNameConstants,
  STORYLY
} from "../../../constants";
import {
  TextField,
  Autocomplete,
  Chip,
  Button,
  MenuItem,
  IconButton,
  Tooltip,
  Box
} from "../../../materialUi/components";
import { InfoIcon } from "../../../materialUi/icons";
import JsonConfigDialog from "../../../component/jsonConfigDialogV2";
import CustomFieldInfoPopup from "../CustomFieldInfoPopup";

const CustomRowType = ({
  handleAddRowTypeCustomField,
  handleUpdateSelectedModuleType,
  handleUpdateRowTypeCustomData,
  handleRemoveRowTypeCustomField,
  customData,
  flashSalePageOptions,
  deviceType
}) => {
  const JSON_MODAL = "JSON_MODAL";
  const [customModuleOptions, setCustomModuleOptions] = useState(
    cloneDeep(customModuleNames)
  );
  const [customFieldSelected, setCustomFieldSelected] = useState(STORYLY);
  const [customFieldPopup, setCustomFieldPopup] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [showModal, setShowModal] = useState({
    [JSON_MODAL]: false
  });

  useEffect(() => {
    const updatedCustomModuleOptions = cloneDeep(customModuleOptions);
    if (deviceType === deviceTypes.desktop) {
      updatedCustomModuleOptions[10].isDisabled = true;
    } else {
      delete updatedCustomModuleOptions[10].isDisabled;
    }
    setCustomModuleOptions(updatedCustomModuleOptions);
  }, [deviceType]);

  const handleCloseCustomFieldPopup = () => {
    setCustomFieldPopup(false);
  };

  const handleModal = (modalName, value) =>
    setShowModal({ [modalName]: value });

  const OnConfirmJsonConfigModal = (index, value) =>
    handleUpdateRowTypeCustomData(index, "value", value);

  return (
    <>
      <Button
        variant="contained"
        size="small"
        onClick={handleAddRowTypeCustomField}
        sx={{ marginBottom: "20px" }}
      >
        Add a new custom field
      </Button>

      {showModal[JSON_MODAL] && (
        <JsonConfigDialog
          handleSave={data => {
            OnConfirmJsonConfigModal(selectedIndex, data);
            handleModal(JSON_MODAL, false);
            setSelectedIndex(-1);
          }}
          value={customData[selectedIndex].value || ""}
          handleClose={() => {
            handleModal(JSON_MODAL, false);
            setSelectedIndex(-1);
          }}
          open={true}
          title={"JSON Configuration"}
          dialogContext={"Please Configure JSON Details here."}
        />
      )}
      <Box sx={{ width: "100%" }}>
        {customData.map(({ key, value }, index) => (
          <Box
            key={index}
            sx={{
              display: "flex",
              marginBottom: "20px",
              alignItems: "center"
            }}
          >
            <Box sx={{ width: "40%", marginRight: "20px" }}>
              <TextField
                autoFocus
                name="key"
                label="Key"
                type="text"
                value={key || ""}
                onChange={event => {
                  let keyName = get(event, "target.value", "").trimStart();
                  handleUpdateRowTypeCustomData(index, "key", keyName);
                }}
                style={{ width: "100%" }}
                size="small"
                InputLabelProps={{ shrink: true }}
                placeholder="Please Enter Key Name"
                disabled={
                  index === 0 ||
                  (index <= 1 &&
                    customModulesWithOneStaticRow.includes(
                      customData[0].value
                    )) ||
                  (index <= 2 &&
                    customModulesWithTwoStaticRow.includes(
                      customData[0].value
                    )) ||
                  (index <= 3 &&
                    customModulesWithThreeStaticRow.includes(
                      customData[0].value
                    )) ||
                  (index <= 4 &&
                    customModulesWithFourStaticRow.includes(
                      customData[0].value
                    ))
                }
              />
            </Box>
            <Box sx={{ width: "40%", marginRight: "20px" }}>
              {index === 0 && (
                <Box sx={{ display: "flex" }}>
                  <TextField
                    select
                    label="Select Module Name"
                    name="value"
                    onChange={event => {
                      handleUpdateSelectedModuleType(event.target.value);
                      setCustomFieldSelected(event.target.value);
                    }}
                    size="small"
                    value={value || ""}
                    sx={{ width: "90%" }}
                  >
                    {customModuleOptions.map(({ label, value, isDisabled }) => (
                      <MenuItem
                        key={value}
                        value={value}
                        disabled={isDisabled || false}
                      >
                        {label}
                      </MenuItem>
                    ))}
                  </TextField>
                  <Tooltip title="Info" placement="top" arrow>
                    <IconButton
                      sx={{ marginLeft: "20px" }}
                      onClick={() => setCustomFieldPopup(true)}
                    >
                      <InfoIcon />
                    </IconButton>
                  </Tooltip>
                  {customFieldPopup && (
                    <CustomFieldInfoPopup
                      deviceType={deviceType}
                      customField={customFieldSelected}
                      onHide={handleCloseCustomFieldPopup}
                    />
                  )}
                </Box>
              )}
              {index === 1 &&
                customData[0].value === customModuleNameConstants.FLASHSALE && (
                  <TextField
                    select
                    label="Select Page Name"
                    onChange={event =>
                      handleUpdateRowTypeCustomData(
                        index,
                        "value",
                        event.target.value
                      )
                    }
                    size="small"
                    value={value || ""}
                    sx={{ width: "100%" }}
                  >
                    {flashSalePageOptions.map(({ label, value }) => (
                      <MenuItem key={value} value={value}>
                        {label}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              {index === 1 &&
                customData[0].value === customModuleNameConstants.GIFTCARD && (
                  <Autocomplete
                    options={[]}
                    freeSolo
                    multiple
                    value={value ? value.split(",") : []}
                    renderTags={(value, props) =>
                      value.map((option, index) => (
                        <Chip
                          label={option}
                          {...props({ index })}
                          size="small"
                        />
                      ))
                    }
                    renderInput={params => (
                      <TextField type="Number" {...params} size="small" />
                    )}
                    onChange={(event, value) => {
                      handleUpdateRowTypeCustomData(
                        index,
                        "value",
                        value.toString()
                      );
                    }}
                    sx={{ width: "100%" }}
                  />
                )}
              {index === 1 &&
                customData[0].value ===
                  customModuleNameConstants.CONNECT_WITH_US && (
                  <Button
                    onClick={() => {
                      setSelectedIndex(index);
                      handleModal(JSON_MODAL, true);
                    }}
                    variant="contained"
                    size="small"
                  >
                    {value ? "EDIT JSON" : "ADD JSON"}
                  </Button>
                )}
              {index === 1 &&
                customData[0].value ===
                  customModuleNameConstants.VIDEO_PLAYER && (
                  <TextField
                    autoFocus
                    name="value"
                    label="Value"
                    type="text"
                    value={
                      value ||
                      handleUpdateRowTypeCustomData(
                        index,
                        "value",
                        `${Math.floor(Math.random() * Date.now())}`
                      )
                    }
                    style={{ width: "100%" }}
                    size="small"
                    InputLabelProps={{ shrink: true }}
                    placeholder="Value"
                    disabled
                  />
                )}
              {index !== 0 &&
                !(
                  index === 1 &&
                  customData[0].value === customModuleNameConstants.FLASHSALE
                ) &&
                !(
                  index === 1 &&
                  customData[0].value === customModuleNameConstants.GIFTCARD
                ) &&
                !(
                  index === 1 &&
                  customData[0].value ===
                    customModuleNameConstants.CONNECT_WITH_US
                ) &&
                !(
                  index === 1 &&
                  customData[0].value === customModuleNameConstants.VIDEO_PLAYER
                ) && (
                  <TextField
                    autoFocus
                    name="value"
                    label="Value"
                    type="text"
                    value={value || ""}
                    onChange={event => {
                      handleUpdateRowTypeCustomData(
                        index,
                        "value",
                        get(event, "target.value", null)
                      );
                    }}
                    style={{ width: "100%" }}
                    size="small"
                    InputLabelProps={{ shrink: true }}
                    placeholder="Value"
                  />
                )}
            </Box>
            <Box sx={{ width: "10%" }}>
              <Button
                disabled={
                  index === 0 ||
                  (index <= 1 &&
                    customModulesWithOneStaticRow.includes(
                      customData[0].value
                    )) ||
                  (index <= 2 &&
                    customModulesWithTwoStaticRow.includes(
                      customData[0].value
                    )) ||
                  (index <= 3 &&
                    customModulesWithThreeStaticRow.includes(
                      customData[0].value
                    )) ||
                  (index <= 4 &&
                    customModulesWithFourStaticRow.includes(
                      customData[0].value
                    ))
                }
                onClick={handleRemoveRowTypeCustomField(index)}
                variant="contained"
                size="small"
              >
                Remove
              </Button>
            </Box>
          </Box>
        ))}
      </Box>
    </>
  );
};

export default CustomRowType;
