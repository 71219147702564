import React, { Component } from "react";
import { connect } from "react-redux";
import isEqual from "lodash/isEqual";
import { bindActionCreators } from "redux";
import Footer from "../../component/footer";
import {
  URL_PAGE_PANEL,
  MESSAGES,
  URL_PAGE_TYPE_DROPDOWN,
  URL_PAGE_MESSAGES,
  LANGUAGE,
  URL_TYPES,
} from "../../constants";
import UpdatePageContent from "./updatePageContent";
import {
  updateActiveUrlPage,
  updateUrlData,
  addUrlData
} from "../../actions/urlCreation.action";
import { snackbar } from "../../actions/common.action";
import { getDefaultUrlContentStructure } from "../../util";
import { Button, Tooltip } from "../../materialUi/components";

class Content extends Component {
  constructor(props) {
    super(props);
    this.state = { isUrlValidated: true };
  }

  componentDidUpdate(prevProps) {
    const { isUrlValidated } = this.state;
    const {
      activePage: {
        filterCondition_rfc: prevFilterCondition = "",
        url: prevUrl = ""
      } = {}
    } = prevProps;
    const {
      activePage: { filterCondition_rfc = "", url = "" } = {},
      activePage,
      initialActivePage
    } = this.props;
    if (!isEqual(activePage, initialActivePage) && isUrlValidated) {
      if (!isEqual(prevUrl, url)) {
        this.setState({ isUrlValidated: false });
      } else if (
        filterCondition_rfc &&
        !isEqual(prevFilterCondition, filterCondition_rfc)
      ) {
        this.setState({ isUrlValidated: false });
      }
    }
  }

  handleReset = () => {
    const { initialActivePage, updateActiveUrlPage } = this.props;
    updateActiveUrlPage(initialActivePage);
    this.setState({ isUrlValidated: true });
  };

  handleSeoContentValidation = () => {
    const { activePage = {}, snackbar } = this.props;
    if (
      ![
        URL_PAGE_TYPE_DROPDOWN.productListing,
        URL_PAGE_TYPE_DROPDOWN.flashSale
      ].includes(activePage.pageType)
    )
      return true;
    const {
      meta: { [LANGUAGE.ENGLISH]: eng = {}, [LANGUAGE.ARABIC]: ar = {} } = {}
    } = activePage;
    if (!eng.title) {
      snackbar({
        open: true,
        severity: "error",
        text: URL_PAGE_MESSAGES.en_title_seo_miss
      });
      return false;
    }
    if (!eng.metaKeyword) {
      snackbar({
        open: true,
        severity: "error",
        text: URL_PAGE_MESSAGES.en_meta_seo_miss
      });
      return false;
    }
    if (!eng.metaDescription) {
      snackbar({
        open: true,
        severity: "error",
        text: URL_PAGE_MESSAGES.en_meta_des_seo_miss
      });
      return false;
    }
    if (!eng.headingH1) {
      snackbar({
        open: true,
        severity: "error",
        text: URL_PAGE_MESSAGES.en_h1_seo_miss
      });
      return false;
    }
    if (!eng.headingH2) {
      snackbar({
        open: true,
        severity: "error",
        text: URL_PAGE_MESSAGES.en_h2_seo_miss
      });
      return false;
    }
    if (!ar.title) {
      snackbar({
        open: true,
        severity: "error",
        text: URL_PAGE_MESSAGES.ar_title_seo_miss
      });
      return false;
    }
    if (!ar.metaKeyword) {
      snackbar({
        open: true,
        severity: "error",
        text: URL_PAGE_MESSAGES.ar_meta_seo_miss
      });
      return false;
    }
    if (!ar.metaDescription) {
      snackbar({
        open: true,
        severity: "error",
        text: URL_PAGE_MESSAGES.ar_meta_des_seo_miss
      });
      return false;
    }
    if (!ar.headingH1) {
      snackbar({
        open: true,
        severity: "error",
        text: URL_PAGE_MESSAGES.ar_h1_seo_miss
      });
      return false;
    }
    if (!ar.headingH2) {
      snackbar({
        open: true,
        severity: "error",
        text: URL_PAGE_MESSAGES.ar_h2_seo_miss
      });
      return false;
    }
    return true;
  };

  handlePageFormValidation = () => {
    const { currentTab, activePage = {}, snackbar } = this.props;
    let url = activePage.url || "";
    let pageType = activePage.pageType || "";
    let filterCondition = activePage.filterCondition_rfc || "";
    let pageContent = activePage.pageContent || "";

    if (!pageType) {
      snackbar({
        open: true,
        severity: "error",
        text: URL_PAGE_MESSAGES.page_type_miss
      });
      return false;
    }
    if (
      [
        URL_PAGE_TYPE_DROPDOWN.productListing,
        URL_PAGE_TYPE_DROPDOWN.productCatalog
      ].includes(pageType) &&
      (filterCondition.charAt(0) === "?" ||
        filterCondition.charAt(0) === " " ||
        /^\s*$/.test(filterCondition))
    ) {
      snackbar({
        open: true,
        severity: "error",
        text: URL_PAGE_MESSAGES.invalid_filter_con
      });

      return false;
    }
    if (url.charAt(0) !== "/" || url.includes("//")) {
      snackbar({
        open: true,
        severity: "error",
        text: URL_PAGE_MESSAGES.invalid_url
      });

      return false;
    }
    if (
      currentTab === URL_PAGE_PANEL.URL_ADD_PAGE_DISPLAY &&
      url.charAt(url.length - 1) !== "/"
    ) {
      snackbar({
        open: true,
        severity: "error",
        text: URL_PAGE_MESSAGES.invalid_url_end
      });
      return false;
    }
    if (currentTab === URL_PAGE_PANEL.URL_ADD_PAGE_DISPLAY &&
      (url.includes("/brands/") || url.includes("/brand/"))) {
      snackbar({
        open: true,
        severity: "error",
        text: URL_PAGE_MESSAGES.invalid_url_brands
      });

      return false;
    }
    if (pageType === URL_PAGE_TYPE_DROPDOWN.flashSale && !pageContent) {
      snackbar({
        open: true,
        severity: "error",
        text: URL_PAGE_MESSAGES.page_content_miss
      });
      return false;
    }

    return true;
  };

  handleAddSubmit = async () => {
    const {
      activePage = {},
      addUrlData,
      loadFirstPageDataHandler
    } = this.props;
    let payload = {
      url: activePage.url || "",
      filterCondition: activePage.filterCondition || "",
      filterCondition_rfc: activePage.filterCondition_rfc || "",
      pageType: activePage.pageType || "",
      pageContent: activePage.pageContent || "",
      meta: activePage.meta || {},
      isIndexable: activePage.isIndexable || false,
      urlType: URL_TYPES.CUSTOM,
      allowedUsers: activePage.allowedUsers || [],
      content:
        activePage.content && activePage.content.length
          ? activePage.content
          : getDefaultUrlContentStructure()
    };

    let res = await addUrlData(payload);
    if (res && res.data && res.data.code === 200) {
      loadFirstPageDataHandler();
    }
  };

  handleUpdateSubmit = async () => {
    const {
      activePage = {},
      updateUrlData,
      initialActivePage = {}
    } = this.props;
    updateUrlData(activePage._id, {
      oldUrl: initialActivePage.url,
      url: activePage.url || "",
      filterCondition: activePage.filterCondition || "",
      filterCondition_rfc: activePage.filterCondition_rfc || "",
      pageType: activePage.pageType || "",
      pageContent: activePage.pageContent || "",
      isIndexable: activePage.isIndexable || false,
      allowedUsers: activePage.allowedUsers || [],
      meta: activePage.meta || {},
      content:
        activePage.content && activePage.content.length
          ? activePage.content
          : getDefaultUrlContentStructure()
    });
  };

  isUrlValidatedHandler = value => {
    this.setState({ isUrlValidated: value });
  };

  render() {
    const {
      currentTab,
      activePage,
      accessRights,
      initialActivePage
    } = this.props;
    const { isUrlValidated } = this.state;

    const isCancelButtonDisable = isEqual(activePage, initialActivePage);

    return (
      <div className="content-container-wrapper">
        <div>
          <UpdatePageContent
            currentTab={currentTab}
            activePage={activePage}
            accessRights={accessRights}
            isUrlValidatedHandler={this.isUrlValidatedHandler}
          />
        </div>
        <Footer>
          <Button onClick={this.handleReset} disabled={isCancelButtonDisable}>
            Cancel
          </Button>
          {currentTab === URL_PAGE_PANEL.URL_ADD_PAGE_DISPLAY && (
            <Tooltip
              title={accessRights.WRITE ? "" : MESSAGES.TOOLTIP_WRITE}
              placement="top"
              arrow
            >
              <span>
                <Button
                  onClick={
                    accessRights.WRITE
                      ? () =>
                        this.handlePageFormValidation() &&
                        this.handleSeoContentValidation() &&
                        this.handleAddSubmit()
                      : null
                  }
                  disabled={
                    !accessRights.WRITE ||
                    !(isUrlValidated && !isCancelButtonDisable)
                  }
                  variant="contained"
                  sx={{ float: "right" }}
                >
                  Save Changes
                </Button>
              </span>
            </Tooltip>
          )}
          {currentTab === URL_PAGE_PANEL.URL_EDIT_PAGE_DISPLAY && (
            <Tooltip
              title={accessRights.UPDATE ? "" : MESSAGES.TOOLTIP_UPDATE}
              placement="top"
              arrow
            >
              <span>
                <Button
                  onClick={
                    accessRights.UPDATE
                      ? () =>
                        this.handlePageFormValidation() &&
                        this.handleSeoContentValidation() &&
                        this.handleUpdateSubmit()
                      : null
                  }
                  disabled={
                    !accessRights.UPDATE ||
                    !(isUrlValidated && !isCancelButtonDisable)
                  }
                  variant="contained"
                  sx={{ float: "right" }}
                >
                  Update Changes
                </Button>
              </span>
            </Tooltip>
          )}
        </Footer>
      </div>
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({
  updateActiveUrlPage: bindActionCreators(updateActiveUrlPage, dispatch),
  snackbar: bindActionCreators(snackbar, dispatch),
  updateUrlData: bindActionCreators(updateUrlData, dispatch),
  addUrlData: bindActionCreators(addUrlData, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(Content);
